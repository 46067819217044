.formContainer {
    position: relative;
    width: "28.125rem";
    height: auto;
    padding: "5rem";
    left: 50;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    margin: 2rem;
  }
  .mainContainer {
    display: grid;
    position: relative;
    justify-content: center;
    z-index: 5;
    align-items: center;
  }