.root{
width: 50%;
margin: "10rem auto";
margin-top: 50px;
left: 25%;
background-color:transparent;
position: relative;
align-items: center;
padding: 15px;
}


