
    .root{
        width: 30%;
        height: 100%;
        /* margin-top: 10px; */
        margin-bottom: 100px;
        left: 35%;
        background-color:transparent;
        position: relative;
        align-items: center;
        padding: 15px;
        }

        .root2{
            width: 100%;
            height: 100%;
            /* margin-top: 2px;
            margin-bottom: 100px; */
            background-color:transparent;
            position: relative;
            align-items: center;
            /* margin: "5 auto"; */
            }

        .formContainer {
            position: relative;
            width: "20.125rem";
            height: 100%;
            padding: "1rem";
            justify-content: center;
            /* padding-left: 15px;
            padding-right: 15px; */
            margin: 12rem auto;
        }

        .formContainer2{
            position: relative;
            height: 100%;
            padding: "1rem";
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
            margin: 2rem auto;
        }
