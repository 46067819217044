body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 
.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.RegistrationForms_root__27EdG{
width: 50%;
margin: "10rem auto";
margin-top: 50px;
left: 25%;
background-color:transparent;
position: relative;
align-items: center;
padding: 15px;
}



.PersonalInformation_mainContainer__3PLGj {
    display: grid;
    position: relative;
    justify-content: center;
    z-index: 5;
    align-items: center;
  }
  .PersonalInformation_formContainer__2intp {
    position: relative;
    width: "28.125rem";
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    margin: 2rem;
  }
  .PersonalInformation_textField__2MI21 {
    margin: 2rem;
    margin-bottom: 1rem !important;
    width: 75%;
    padding-bottom: 1rem;
  }
  .PersonalInformation_optionButton__3pYit{
    padding: 10px;
    width: 75%;
    margin: 1rem;
  }
.SignUp_mainContainer__fDhN- {
  display: grid;
  position: relative;
  justify-content: center;
  z-index: 5;
  align-items: center;
}
.SignUp_formContainer__2RORH {
  position: relative;
  width: "28.125rem";
  height: auto;
  padding: "1rem";
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  margin: 2rem;
}
.SignUp_textField__1ZPtD {
  margin: 2rem;
  margin-bottom: 1rem !important;
  width: 75%;
  padding-bottom: 1rem;
}
.SignUp_optionButton__2XYxq{
  padding: 10px;
  width: 75%;
  margin: 1rem;
}
.BankDetails_mainContainer__1q10B {
    display: grid;
    position: relative;
    justify-content: center;
    z-index: 5;
    align-items: center;
  }
  .BankDetails_formContainer__1qzv- {
    position: relative;
    width: "28.125rem";
    height: auto;
    padding: "1rem";
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    margin: 2rem;
  }
  .BankDetails_textField__3d1TL {
    margin: 2rem;
    margin-bottom: 1rem !important;
    width: 75%;
    padding-bottom: 1rem;
  }
  .BankDetails_optionButton__CthYM{
    padding: 10px;
    width: 75%;
    margin: 1rem;
  }
.TsAndCs_formContainer__1M-s6 {
    position: relative;
    width: "28.125rem";
    height: auto;
    padding: "5rem";
    left: 50;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    margin: 2rem;
  }
  .TsAndCs_mainContainer__2Qwos {
    display: grid;
    position: relative;
    justify-content: center;
    z-index: 5;
    align-items: center;
  }

    .Login_root__3AxL4{
        width: 30%;
        height: 100%;
        /* margin-top: 10px; */
        margin-bottom: 100px;
        left: 35%;
        background-color:transparent;
        position: relative;
        align-items: center;
        padding: 15px;
        }

        .Login_root2__2bopI{
            width: 100%;
            height: 100%;
            /* margin-top: 2px;
            margin-bottom: 100px; */
            background-color:transparent;
            position: relative;
            align-items: center;
            /* margin: "5 auto"; */
            }

        .Login_formContainer__33EyD {
            position: relative;
            width: "20.125rem";
            height: 100%;
            padding: "1rem";
            justify-content: center;
            /* padding-left: 15px;
            padding-right: 15px; */
            margin: 12rem auto;
        }

        .Login_formContainer2__2Q5Ya{
            position: relative;
            height: 100%;
            padding: "1rem";
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
            margin: 2rem auto;
        }

.Home_container__3RWji {
  width: 100%;
  margin: "10 auto";
  background-color: transparent;
  position: relative;
  
  /* 
  text-align: center;
  align-items: center;
  justify-content: center; */
}
.Home_containerer__eFFWW {
  width: 100%;
  background-color: transparent;
  position: relative;
  align-items: center;
  text-align: center;
  margin: "5 auto";
}

