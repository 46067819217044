.mainContainer {
    display: grid;
    position: relative;
    justify-content: center;
    z-index: 5;
    align-items: center;
  }
  .formContainer {
    position: relative;
    width: "28.125rem";
    height: auto;
    padding: "1rem";
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    margin: 2rem;
  }
  .textField {
    margin: 2rem;
    margin-bottom: 1rem !important;
    width: 75%;
    padding-bottom: 1rem;
  }
  .optionButton{
    padding: 10px;
    width: 75%;
    margin: 1rem;
  }