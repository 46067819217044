.container {
  width: 100%;
  margin: "10 auto";
  background-color: transparent;
  position: relative;
  
  /* 
  text-align: center;
  align-items: center;
  justify-content: center; */
}
.containerer {
  width: 100%;
  background-color: transparent;
  position: relative;
  align-items: center;
  text-align: center;
  margin: "5 auto";
}
